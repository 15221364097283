<template>
    <div>
        <!-- page-wrapper Start-->
		<div class="page-wrapper">
			<CCard class="p-5">
				<div class="error-wrapper">
					<div v-if="message == 'success'" class="text-center">
						<svg fill="#ffcb4c" width="150px" height="150px" viewBox="-8 0 512 512" xmlns="http://www.w3.org/2000/svg" stroke="#ffcb4c">
							<g id="SVGRepo_bgCarrier" stroke-width="0"/>
							<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
							<g id="SVGRepo_iconCarrier">
							<path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm80 168c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm-160 0c17.7 0 32 14.3 32 32s-14.3 32-32 32-32-14.3-32-32 14.3-32 32-32zm194.8 170.2C334.3 380.4 292.5 400 248 400s-86.3-19.6-114.8-53.8c-13.6-16.3 11-36.7 24.6-20.5 22.4 26.9 55.2 42.2 90.2 42.2s67.8-15.4 90.2-42.2c13.4-16.2 38.1 4.2 24.6 20.5z"/>
							</g>
						</svg>
						<div class="">
							<h2 class="text-success">Payment Success.</h2>
						</div>
						<div class="text-center">
							<p class="sub-content">You have successfully activated premium plan. Thank You...</p>
						</div>
						<div><router-link class="text-info" :to="{ path: '/' }">BACK TO HOME PAGE</router-link></div>
					</div>
					<div v-if="message == 'error'" class="text-center">
						<svg fill="#ffcb4c" width="150px" height="150px" viewBox="0 0 36 36" version="1.1" preserveAspectRatio="xMidYMid meet">
						<g id="SVGRepo_bgCarrier" stroke-width="0"/>
						<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"/>
						<g id="SVGRepo_iconCarrier"> <title>sad-face-solid</title> <path d="M18,2A16,16,0,1,0,34,18,16,16,0,0,0,18,2Zm9,12.28a1.8,1.8,0,1,1-1.8-1.8A1.8,1.8,0,0,1,27,14.28Zm-15.55,1.8a1.8,1.8,0,1,1,1.8-1.8A1.8,1.8,0,0,1,11.41,16.08Zm14,7.53a1,1,0,0,1-1.6,1.2,7,7,0,0,0-11.31.13,1,1,0,1,1-1.63-1.16,9,9,0,0,1,14.54-.17Z" class="clr-i-solid clr-i-solid-path-1"/> <rect x="0" y="0" width="36" height="36" fill-opacity="0"/> </g>

						</svg>
						<div class="">
							<h2 class="text-danger">Payment Failed.</h2>
						</div>
						<div class="">
							<p class="sub-content">Plan Activation Failed. Plase try Again.</p>
						</div>
						<div><router-link class="text-info" :to="{ path: '/' }">BACK TO HOME PAGE</router-link></div>
					</div>
				</div>
			</CCard>
		</div>
    </div>
</template>



<script>

export default {
    name: "Success",
    data() {
        return {
            message : ''
        }
    },
    mounted() {
		console.log('in success page')
		console.log(this.$route.query)
        const message = this.$route.query.message.toLowerCase()
        if(message)this.message = message 
    },
    methods: {
    }
}
</script>